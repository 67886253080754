import * as React from 'react';
import { Select, TYPE } from 'baseui/select';
import BaseClass from './base';
import store from '../../../redux/store';
class SelectComponent extends BaseClass {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
    };
  }

  render() {
    var value = this.state.value;
    return <>
      <h5
        style={{
          color: this.props.theme.colors.primaryA,
          marginTop: this.props.theme.sizing.scale1200,
          fontFamily: 'IBM Plex Sans',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '16px'
        }}
      >
        {this.props.title || ''}
      </h5>
      <SelectCXL
        {...this.props}
        value={value}
        onChange={(v) => {
          this.setState({ value: v })
        }}
      ></SelectCXL>
    </>
  }
}

export const SelectCXL = (props) => {
  let value = props.value;
  let options = props.options;
  const componentState = props.state;
  if (props.loadOptionsFromState) {
    options = props.pathToStoreValue
      .split('.')
      .reduce((o, i) => o[i], store.getState());
    if (props.optionFilter) {
      options = props.optionFilter(options)
    }
  }

  if (props.loadOptionsFromComponentState && componentState) {
    options = props.pathToStoreValue
      .split('.')
      .reduce((o, i) => o[i], componentState);
    if (props.optionFilter) {
      options = props.optionFilter(options)
    }
  }

  if (props.formatOptions) {
    options= props.formatOptions(options);
  }

  if (typeof value === 'string') {
    value = options.find((v) => v.id.toLowerCase() == value.toLowerCase());
  }


  return (
    <>
      <Select
        options={options}
        onChange={({ value }) => {
          if (props.multi) {
            props.onChange(value);
            return
          }
          props.onChange(value[0].id)
        }}
        value={value}
        placeholder={value ? '' : props.placeholder}
        multi={props.multi || false}
        labelKey={props.labelKey || 'label'}
        valueKey="id"
        maxDropdownHeight="300px"
        type={TYPE.search}
        overrides={props.overrides}
      />
    </>
  );
}

export default SelectComponent;
